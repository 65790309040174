<template>
  <div>
    <div class="y-desc" style="font-size: 1rem;line-height: 2rem;margin-bottom: .5rem">
      用户积分总量 累计获取{{desc.get}} | 累计消耗{{desc.out}}
    </div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="nickname" label="昵称" show-overflow-tooltip></el-table-column>
        <el-table-column prop="phone" label="手机号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="points" label="积分" show-overflow-tooltip></el-table-column>
        <el-table-column label="积分变动明细">
          <template v-slot="s">
              <UserPointsLog :uid="s.row.id">
                <el-button>查看明细列表</el-button>
              </UserPointsLog>
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-card>
  </div>
</template>

<script>


import UserPointsLog from "@/components/shop/plugin/pointsMall/userPointsLog/UserPointsLog";
export default {
  name: "user",
  components: {UserPointsLog},
  data(){
    return{
      list:[],
      page:1,
      total:0,
      desc:{
        get:0,
        out:0,
      }
    }
  },
  mounted() {
    this.load();
    this.$u.api.shop.plugin.pointsMall.userPointsDesc().then(res=>{
      this.desc = res;
    })
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.load();
    },
    load(){
      this.$u.api.shop.plugin.pointsMall.userSearch({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
      })
    },
  },
}
</script>

<style scoped>

</style>