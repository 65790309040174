<template>
  <div>
    <div @click="open"><slot></slot></div>
    <el-dialog title="积分变动明细" :visible.sync="show" destroy-on-close>
      <el-table :data="list">
        <el-table-column prop="id" label="编号"></el-table-column>
        <el-table-column prop="points" label="变动数量"></el-table-column>
        <el-table-column prop="log" label="记录"></el-table-column>
        <el-table-column prop="log" label="变动时间">
          <template v-slot="s">
            {{s.row.created_at | date}}
          </template>
        </el-table-column>
        <template #append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="15" :current-page="page"
                         @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "UserPointsLog",
  props:{
    uid:{
      type:Number,
      default:0,
    }
  },
  data(){
    return{
      show:false,
      list:[],
      page:1,
      total:0,
    }
  },
  methods:{
    pageChange(e){
      this.page = e;
      this.open();
    },
    open(){
      let load = this.$loading({
        body: true,
        text: "请稍候..."
      })
      this.$u.api.shop.plugin.pointsMall.userPointsLog({page: this.page, uid: this.uid}).then(res => {
        this.list = res.list;
        this.total = res.total;
        this.show = true;
        load.close();
      }).catch(() => {
        load.close();
      })
    }
  }
}
</script>

<style scoped>

</style>